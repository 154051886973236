@font-face {
  font-family: "font1";
  src: url("./font1-Book.eot");
  src: url("./font1-Book.eot?#iefix") format("embedded-opentype"),
    url("./font1-Book.woff2") format("woff2"),
    url("./font1-Book.woff") format("woff"),
    url("./font1-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "font1";
  src: url("./font1-Bold.eot");
  src: url("./font1-Bold.eot?#iefix") format("embedded-opentype"),
    url("./font1-Bold.woff2") format("woff2"),
    url("./font1-Bold.woff") format("woff"),
    url("./font1-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "font2";
  font-weight: normal;
  font-style: normal;
  src: url(./font2-Book.eot) format("eot");
  src: url(./font2-Book.woff) format("woff"),
    url(./font2-Book.ttf) format("truetype");
}

body {
  background-color: #477236;
}
